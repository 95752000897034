<template>
  <base-material-dialog
    :value="value"
    icon="far fa-calendar-edit"
    title="Begegnungsdaten bearbeiten"
    color="primary"
    :actions="[ 'close' ]"
    @close="close"
    @esc="close"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <base-edit-dialog-date-time
          v-if="isMaster"
          :value="Event.startDate"
          :id="id"
          label="Beginn"
          @input="({value, id}) => savefield(id, 'startDate', value, 'DateTime')"
        >
          <template #default>
            <v-text-field
              label="Beginn"
              :value="Event.startDate | dateformat"
              readonly
            />
          </template>
        </base-edit-dialog-date-time>
        <v-text-field
          v-else
          label="Beginn"
          :value="Event.startDate | dateformat"
          disabled
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <base-edit-dialog-hallensuche
          v-if="isMaster"
          :value="venue"
          :id="id"
          label="Halle"
          allownew
          @input="({value, id}) => savefield(id, 'venue', value, 'UUID')"
        />
        <v-text-field
          v-else
          label="Halle"
          :value="venue"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          v-if="isMaster"
          :value="Event.internalComment"
          :id="id"
          label="Anmerkungen"
          textarea
          @input="({value, id}) => savefield(id, 'internalComment', value)"
        />
        <v-textarea
          v-else
          label="Anmerkungen"
          :value="Event.internalComment"
          disabled
        />
      </v-col>
      <v-col cols="12">
        <base-edit-dialog
          v-if="isMaster"
          :value="Event.publicComment"
          :id="id"
          label="öffentliche Bemerkungen"
          textarea
          @input="({value, id}) => savefield(id, 'publicComment', value)"
        />
        <v-textarea
          v-else
          label="öffentliche Bemerkungen"
          :value="Event.publicComment"
          disabled
        />
      </v-col>
    </v-row>
  </base-material-dialog>
</template>

<script>
import gql from 'graphql-tag'
import { useGraphQL } from '@/plugins/graphql'

const query = `
  _id
  _parent
  type
  parent {
    _id
    ... on StbW2023League {
      name
    }
  }
  ... on StbW2023Wk {
    name
    startDate
    _venue
    venue { _id name address { streetAddress streetNumber postalCode addressLocality } }
    internalComment
    publicComment
  }
`

export default {
  name: 'EditEvent',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    id: {
      type: String,
      required: false
    }
  },

  data: () => ({
    Event: {}
  }),

  computed: {
    venue () {
      const v = this.Event.venue
      return !v ? '' : `${v.name} (${v.address.streetAddress} ${v.address.streetNumber}, ${v.address.postalCode} ${v.address.addressLocality})`
    },
    isMaster () {
      return this.$store.getters.isMaster
    }
  },

  methods: {
    close () {
      this.$emit('input', false)
    },
    savefield (id, field, value, type) {
      if (!type) type = 'String'
      if (type === 'Int') value = parseInt(value)

      const q = field[0].toUpperCase() + field.substring(1).toLowerCase()

      this.mutate({
        mutation: gql`
          mutation($id: UUID!, $${field}: ${type}!) {
            StbW2023Wk${q}Update(id: $id, ${field}: $${field}) { _id }
          }
        `,
        variables: {
          id,
          [field]: value
        }
      })
    },
    isTeam (team) {
      return this.$store.getters.isMaster ||
        this.$store.getters.isTeam(team.team._id) ||
        this.$store.getters.isEdv(team.team._id) ||
        this.$store.getters.isClub(team.team.club._id)
    }
  },

  apollo: {
    Event: {
      query: gql`query($id: UUID!) {
        Event(id: $id) { ${query} }
      }`,
      variables () {
        return { id: this.id }
      },
      skip () {
        return !this.id
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
          subscription($id: UUID) { EventUpdate(id: $id) { ${query} }}
        `,
        variables () {
          return {
            id: this.id
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
